<template>
  <div class="rep">
    <div class="title">
      <div class="title-img">
        <img src="~assets/images/icon/start.png" alt="" />
      </div>
      <section>find a rep</section>
    </div>
    <div class="block">
      <div class="font">
        <div class="font-title">Sales Rep Finder</div>
        <div class="font1">
          Special T has an impressive team of sales representatives to serve
          you. If you're a retailer, click on your state below to find your
          sales rep. If you have trouble finding a rep or showroom, contact us
          at:
        </div>
        <div class="info">
          <div class="info-title">{{ getInfo.copyright }}</div>
          <p>{{ getInfo.address }}</p>
          <p>
            Toll Free:
            <a :href="'tel:' + getInfo.tollfree" style="font-weight: 600"
              >{{ getInfo.tollfree }}
            </a>
          </p>
          <p>Fax: {{ getInfo.fax }}</p>
          <p>Email:{{ getInfo.email }}</p>
        </div>
      </div>
      <div class="map col-lg-8 col-xl-8 col-sm-8 col-xs-12">
        <img src="~assets/images/icon/map.png" alt="" />
      </div>

      <div class="list" v-for="obj in list">
        <div class="item">
          <div class="item-title">{{ obj.address }}</div>
          <section>
            <p>{{ obj.repname }}</p>
            <p>PH:{{ obj.phone }}</p>
            <p>FAX:{{ obj.fax }}</p>
          </section>
          <div class="go-top" @click="backTop">TOP OF PAGE</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAllSalesrep } from "network/getAllSalesrep";
import { GetInfo } from "network/contactUs";
export default {
  created() {
    getAllSalesrep().then((res) => {
      this.list = res.data.data;
    });
    this.GetInfo();
  },
  data() {
    return {
      getInfo: {},
      list: [
        {
          title: "Alabama ",
          info: ["Ivystone", "PH: 610.524.6400", "FAX: 610.524.6429"],
        },
        {
          title: "Alabama ",
          info: ["Ivystone", "PH: 610.524.6400", "FAX: 610.524.6429"],
        },
        {
          title: "Alabama ",
          info: ["Ivystone", "PH: 610.524.6400", "FAX: 610.524.6429"],
        },
        {
          title: "Alabama ",
          info: ["Ivystone", "PH: 610.524.6400", "FAX: 610.524.6429"],
        },
      ],
    };
  },
  methods: {
    backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    GetInfo() {
      GetInfo().then((res) => {
        this.getInfo = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.rep {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
.title {
  background: #ff7f00;
  display: flex;
  font-size: 0.75rem;
  color: #fff;
  align-items: center;
  padding: 0.25rem 0.5rem;
}
.title-img {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}
.title-img img {
  width: 0.5rem;
  height: 0.5rem;
}
.block {
  padding: 0.5rem;
}
.font {
  color: #666666;
  font-size: 0.35rem;
}
.font-title {
  color: #ff7f00;
  font-size: 0.4rem;
  font-weight: 600;
}
.font1 {
  margin-top: 0.5rem;
  line-height: 0.75rem;
}
.font2 {
  margin-top: 1rem;
}
.font2 p {
  font-weight: 600;
}
.font2 section {
  margin-top: 0.2rem;
}
.font3 {
  margin-top: 1rem;
}
.font3 section {
  margin-top: 0.2rem;
}
.font3 span {
  font-weight: 600;
}
.map {
  margin-top: 1rem;
}
.map img {
  width: 100%;
}
.go-top {
  text-decoration: underline;
  margin: 0.25rem;
  cursor: pointer;
}
.list {
  margin-top: 1rem;
  font-size: 0.35rem;
  color: #ff7f00;
}
.item-title {
  background: #ffecd9;
  text-decoration: underline;
  padding: 0.25rem;
}
.list section {
  margin: 0.25rem;
}
.list section p {
  margin-top: 0.25rem;
}
.info {
  margin: 0;
  padding: 0;
}
.info p {
  font-size: 0.4rem;
  color: #666666;
  margin-top: 0.5rem;
}
.info-title {
  font-size: 0.5rem;
  margin-top: 1rem;
  color: #333333;
  font-weight: 600;
}
</style>
