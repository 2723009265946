import { request } from "./request";

export function GetInfo() {
    return request({
        url: '/api/siteinfo/getAllSiteinfo',
    })
};

export function sendMessage(name, email, subject, content, ) {
    return request({
        url: '/api/contact/sendMessage',
        method: 'post',
        data: {
            name,
            email,
            subject,
            content,
        },
    })
};